<template>
  <div class="tabs is-boxed">
    <ul>
      <li
        :class="{ 'is-active': $route.name === 'vendor-users' }"
      >
        <router-link :to="{ name: 'vendor-users' }">
          <span class="icon is-small">
            <i
              class="fas fa-cash-register"
              aria-hidden="true"
            />
          </span>
          Vendor Users
        </router-link>
      </li>
      <!-- v-if="onlyFor($ADMINISTRATOR)" -->
      <li
        v-if="isAdmin"
        :class="{ 'is-active': ($route.name === 'application-users') }"
      >
        <router-link :to="{ name: 'application-users' }">
          <span class="icon is-small">
            <i
              class="fas fa-money-bill-alt"
              aria-hidden="true"
            />
          </span>
          Application Users
        </router-link>
      </li>
      <!-- v-if="onlyFor($ADMINISTRATOR)" -->
      <li
        v-if="isAdmin"
        :class="{ 'is-active': $route.name === 'admins' }"
      >
        <router-link :to="{ name: 'admins' }">
          <span class="icon is-small">
            <i
              class="fas fa-cash-register"
              aria-hidden="true"
            />
          </span>
          Admins
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: "UsersTabs",
  mounted() {
    console.log('UsersTabs mounted is running');

  },
};

</script>

<style lang="scss" scoped>
  .tabs {
    li {
      a {
        background-color: #e6e6e6;
      }
    }
  }
</style>
