<template>
  <div class="container">
    <div class="columns">
      <div class="column is-paddingless">
        <users-tabs />
        <div class="tabs-container content fix-margin">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <div class="columns">
                  <div class="column">
                    <h3 class="has-text-centered-mobile is-marginless">
                      Admin List
                    </h3>
                  </div>
                  <div class="column has-text-right has-text-centered-mobile">
                    <button
                      class="button is-primary"
                      @click="addNew"
                    >
                      Add Admin
                    </button>
                  </div>
                </div>
                <div :class="{ 'hide-edges': hideEdges }">
                  <v-client-table
                    ref="allAdmins"
                    :columns="tableColumns"
                    :options="tableOptions"
                    :data="tableValues"
                  >
                    <template
                      slot="emailAddress"
                      slot-scope="props"
                    >
                      <a
                        href="#"
                        @click.prevent="edit(props.row)"
                      >{{ props.row.emailAddress }}</a>
                    </template>
                    <template
                      slot="fullName"
                      slot-scope="props"
                    >
                      {{ props.row.firstName }} {{ props.row.lastName }}
                    </template>
                    <template
                      slot="registeredDate"
                      slot-scope="props"
                    >
                      {{ props.row.registeredDate }}
                    </template>
                    <template
                      slot="lastLoginDate"
                      slot-scope="props"
                    >
                      {{ props.row.lastLoginDate }}
                    </template>
                    <template
                      slot="modifiedDate"
                      slot-scope="props"
                    >
                      {{ props.row.modifiedDate }}
                    </template>
                  </v-client-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      ref="edit-admin"
      class="is-small"
      :settings="{ title: 'Edit Admin Profile' }"
    >
      <div class="column is-full content">
        <ValidationObserver
          v-slot="validation"
          tag="div"
        >
          <p>Enter administrator e-mail address</p>
          <p><small>The user will receive an e-mail with the instructions to set their password.</small></p>

          <input-form :errors="validation.errors">
            <div class="field">
              <div class="control">
                <vee-textbox
                  v-if="action !== 'edit'"
                  id="emailAddress"
                  v-model="adminObject.emailAddress"
                  name="E-mail Address"
                  placeholder="E-mail Address"
                  type="email"
                  rules="required|email"
                  required
                />
                <textbox
                  v-if="action === 'edit'"
                  id="emailAddress"
                  v-model="adminObject.emailAddress"
                  placeholder="E-mail Address"
                  name="E-mail Address"
                  type="email"
                  rules="required|email"
                  disabled
                  required
                />
              </div>
            </div>
            <div
              v-if="action === 'add'"
              class="field"
            >
              <div class="control">
                <vee-textbox
                  id="confirmEmailAddress"
                  v-model="adminObject.confirmEmailAddress"
                  name="Re-entered E-mail Address"
                  placeholder="Re-enter the user E-mail Address"
                  rules="required|confirmed:E-mail Address"
                  required
                />
                <!-- type="email" -->
              </div>
            </div>
            <div class="field">
              <div class="control">
                <vee-textbox
                  id="firstName"
                  v-model="adminObject.firstName"
                  name="First Name"
                  placeholder="First Name"
                  type="text"
                  rules="required"
                  required
                />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <vee-textbox
                  id="lastName"
                  v-model="adminObject.lastName"
                  name="Last Name"
                  placeholder="Last Name"
                  type="text"
                  rules="required"
                  required
                />
              </div>
            </div>
            <input
              v-model="adminObject.id"
              type="hidden"
            >
            <div class="field is-grouped is-justified-center">
              <div class="control">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': updatingProfile }"
                  :disabled="updatingProfile"
                  @click.prevent="submitAdminForm(validation)"
                >
                  {{ action === 'add' ? 'Add' : 'Submit' }}
                </button>
              </div>
              <div
                v-if="action === 'edit'"
                class="control"
              >
                <button
                  class="button is-danger"
                  :class="{ 'is-loading': updatingProfile }"
                  :disabled="updatingProfile"
                  @click.prevent="deleteAdmin"
                >
                  Delete
                </button>
              </div>
            </div>
          </input-form>
        </ValidationObserver>
      </div>
    </modal>
  </div>
</template>
<script>

import Modal from '@/components/common/Modal';
import UsersTabs from '@/components/UsersTabs';
import { options } from '@/config/tableOptions';

import {
  Textbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, email, confirmed } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

let tableColumns = [
  "emailAddress",
  "fullName",
  "registeredDate",
  "lastLoginDate",
  "modifiedBy",
  "modifiedDate",
];

export default {
  components: {
    Modal,
    UsersTabs,
    VeeTextbox,
    ValidationObserver,
  },
  data() {
    return {
      adminObject: {},
      action: '',
      confirmUserName: '',
      tableColumns,
      tableOptions: options({
        headings: {
          emailAddress: "Email Address (User Name)",
          fullName: "Full Name",
          registeredDate: "Registered\nDate",
          lastLoginDate: "Last Login\nDate",
          modifiedBy: "Modified By",
          modifiedDate: "Modified\nDate",
        },
        columnsClasses: {
          lastLoginDate: 'date',
          registeredDate: 'date',
          modifiedDate: 'date',
          modifiedBy: 'td-nowrap',
          emailAddress: 'td-nowrap',
        },
        sortable: [ 'emailAddress' ],
        dateFormat: "MM/DD/YYYY HH:mm",
        resizableColumns: true,
      }),
    };
  },
  computed: {
    tableValues() {
      return this.$store.state.users.admins;
    },
    updatingProfile() {
      return this.$store.state.users.updatingProfile;
    },
  },
  async fetch({ store }) {
    await store.dispatch('users/getUsers');
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
    });
    extend('confirmed', {
      ...confirmed,
    });
  },
  methods: {
    edit(user) {
      this.action = 'edit';
      // this.$store.commit('users/', user);
      this.adminObject = { ...this.adminObject, ...user };
      this.$refs['edit-admin'].show();
    },
    addNew() {
      this.action = 'add';
      this.adminObject = {};
      // this.$store.commit('users/cleanAdminObject');
      this.confirmUserName = '';
      this.$refs['edit-admin'].show();
    },
    hideModal() {
      this.$refs['edit-admin'].hide();
    },
    async submitAdminForm(validation) {
      console.log('Admins.vue submitAdminForm is running');
      // const isValid = await this.$refs['register-form'].validate();
      const isValid = await validation.validate();

      if (!isValid) {
        return false;
      }

      if (this.action === 'add') {
        this.adminObject.role = this.$ADMINISTRATOR;
        const response = await this.$store.dispatch('users/add', this.adminObject);
        if (response) {
          this.$success(`The user ${response.emailAddress} has been successfully created.`);
          this.hideModal();
        }
      } else if (this.action === 'edit') {
        const response = await this.$store.dispatch('users/update', this.adminObject);
        if (response) {
          this.$success(`The admin ${response.emailAddress} has been successfully updated.`);
          this.hideModal();
        }
      } else {
        this.$fatalError();
      }
    },
    deleteAdmin() {
      console.log('Admins.vue deleteAdmin is running');
      this.$modal.show('dialog', {
        title: 'Wait...!',
        text: `Are you sure you want to delete the user ${this.adminObject.emailAddress}?`,
        buttons: [
          {
            title: 'Yes, of Course!',
            handler: async () => {
              await this.$store.dispatch('users/delete', this.adminObject);
              this.$modal.hide('dialog');
              this.hideModal();
            },
          },
          {
            title: 'No',
            default: true,
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
  },
};
</script>
